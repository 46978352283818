.contact-us-container {
  display: flex;
  min-height: 100vh;
  align-items: flex-start;
  flex-direction: column;
  margin: 3em;
}

.contact-us-container-inner {
  padding-left: 300px;
  padding-right: 300px;
  font-size: smaller;
  font-family: serif;
}
