.contact-us-container {
  width: 100%;
  display: flex;
  min-height: 100vh;
  align-items: center;
  flex-direction: column;
}
.contact-us-text {
  display: inline-block;
  text-decoration: none;
}
.contact-us-text01 {
  display: inline-block;
}
.contact-us-text02 {
  display: inline-block;
  text-decoration: none;
}
.contact-us-text03 {
  display: inline-block;
}
.contact-us-text04 {
  display: inline-block;
  font-style: normal;
  font-weight: 600;
}
.contact-us-text05 {
  display: inline-block;
  font-style: normal;
  font-weight: 600;
}
.contact-us-text06 {
  display: inline-block;
  font-style: normal;
  font-weight: 600;
}
.contact-us-text07 {
  display: inline-block;
  font-style: normal;
  font-weight: 600;
}
.contact-us-text08 {
  display: inline-block;
}
.contact-us-text09 {
  display: inline-block;
}
.contact-us-text10 {
  display: inline-block;
}
.contact-us-text11 {
  display: inline-block;
}
.contact-us-text12 {
  display: inline-block;
}
.contact-us-text13 {
  display: inline-block;
}
.contact-us-text14 {
  display: inline-block;
}
.contact-us-text15 {
  display: inline-block;
}
.contact-us-text16 {
  display: inline-block;
}
.contact-us-text17 {
  display: inline-block;
}
.contact-us-text18 {
  display: inline-block;
}
.contact-us-text21 {
  display: inline-block;
}
.contact-us-text22 {
  display: inline-block;
}
.contact-us-text23 {
  display: inline-block;
}
.contact-us-text24 {
  display: inline-block;
}
.contact-us-text25 {
  fill: var(--dl-color-theme-neutral-dark);
  color: var(--dl-color-theme-neutral-dark);
  display: inline-block;
}
@media(max-width: 479px) {
  .contact-us-text18 {
    text-align: center;
  }
}
