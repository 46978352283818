.contact-form7-contact1 {
  display: flex;
  position: relative;
  align-items: center;
  flex-direction: column;
}
.contact-form7-max-width {
  align-items: flex-start;
}
.contact-form7-section-title {
  gap: var(--dl-space-space-unit);
  width: 40%;
  align-items: flex-start;
}
.contact-form7-content {
  gap: var(--dl-space-space-oneandhalfunits);
  display: flex;
  align-self: stretch;
  flex-direction: column;
}
.contact-form7-content1 {
  gap: 16px;
  display: flex;
  padding: 8px 0;
  align-items: flex-start;
  flex-direction: column;
}
.contact-form7-row {
  gap: 16px;
  display: flex;
  align-items: flex-start;
}
.contact-form7-row1 {
  gap: 16px;
  display: flex;
  align-items: flex-start;
}
.contact-form7-row2 {
  gap: 16px;
  display: flex;
  align-items: flex-start;
}
.contact-form7-form {
  gap: var(--dl-space-space-oneandhalfunits);
  width: 60%;
}
.contact-form7-container {
  gap: var(--dl-space-space-unit);
  flex: 0 0 auto;
  width: 100%;
  display: flex;
  align-items: flex-start;
  flex-direction: row;
}
.contact-form7-input {
  gap: var(--dl-space-space-halfunit);
  width: 50%;
  display: flex;
  align-self: stretch;
  align-items: flex-start;
  flex-direction: column;
}
.contact-form7-input1 {
  gap: var(--dl-space-space-halfunit);
  width: 50%;
  display: flex;
  align-self: stretch;
  align-items: flex-start;
  flex-direction: column;
}
.contact-form7-container1 {
  gap: var(--dl-space-space-unit);
  flex: 0 0 auto;
  width: 100%;
  display: flex;
  align-items: flex-start;
  flex-direction: row;
}
.contact-form7-input2 {
  gap: var(--dl-space-space-halfunit);
  width: 50%;
  display: flex;
  align-self: stretch;
  align-items: flex-start;
  flex-direction: column;
}
.contact-form7-input3 {
  gap: var(--dl-space-space-halfunit);
  width: 50%;
  display: flex;
  align-self: stretch;
  align-items: flex-start;
  flex-direction: column;
}
.contact-form7-container2 {
  flex: 0 0 auto;
  width: 100%;
  height: 147px;
  display: flex;
  align-self: flex-start;
  align-items: flex-start;
  flex-direction: column;
}
.contact-form7-input4 {
  gap: var(--dl-space-space-halfunit);
  width: 100%;
  display: flex;
  align-self: flex-start;
  align-items: flex-start;
  flex-direction: column;
}
.contact-form7-checkbox {
  gap: var(--dl-space-space-unit);
  display: flex;
  align-self: flex-start;
  align-items: center;
}
.contact-form7-button {
  align-self: flex-start;
}
.contact-form7-text09 {
  display: inline-block;
}
.contact-form7-text10 {
  display: inline-block;
}
.contact-form7-text11 {
  display: inline-block;
}
.contact-form7-text12 {
  display: inline-block;
}
.contact-form7-text13 {
  display: inline-block;
}
.contact-form7-text14 {
  display: inline-block;
}
.contact-form7-text17 {
  display: inline-block;
}

@media(max-width: 991px) {
  .contact-form7-max-width {
    align-items: flex-start;
    flex-direction: column;
  }
  .contact-form7-section-title {
    width: 100%;
  }
  .contact-form7-form {
    width: 100%;
  }
  .contact-form7-container {
    gap: var(--dl-space-space-twounits);
    width: 100%;
    flex-direction: row;
  }
  .contact-form7-input {
    width: 50%;
  }
  .contact-form7-input1 {
    width: 50%;
    flex-direction: column;
  }
  .contact-form7-container1 {
    gap: var(--dl-space-space-twounits);
    width: 100%;
    flex-direction: row;
  }
  .contact-form7-input2 {
    width: 50%;
  }
  .contact-form7-input3 {
    width: 50%;
  }
  .contact-form7-container2 {
    gap: var(--dl-space-space-twounits);
    width: 100%;
    flex-direction: row;
  }
  .contact-form7-checkbox {
    align-self: flex-start;
  }
}
@media(max-width: 479px) {
  .contact-form7-form {
    padding: var(--dl-space-space-unit);
  }
  .contact-form7-container {
    align-items: stretch;
    flex-direction: column;
  }
  .contact-form7-input {
    width: 100%;
  }
  .contact-form7-input1 {
    width: 100%;
  }
  .contact-form7-container1 {
    align-items: stretch;
    flex-direction: column;
  }
  .contact-form7-input2 {
    width: 100%;
  }
  .contact-form7-input3 {
    width: 100%;
  }
  .contact-form7-text14 {
    text-align: center;
  }
}
