.home-container {
  width: 100%;
  display: flex;
  min-height: 100vh;
  align-items: center;
  flex-direction: column;
}
.home-text {
  display: inline-block;
  text-decoration: none;
}
.home-text01 {
  display: inline-block;
}
.home-text02 {
  display: inline-block;
  text-decoration: none;
}
.home-text03 {
  display: inline-block;
}
.home-text04 {
  display: inline-block;
  font-style: normal;
  font-weight: 600;
}
.home-text05 {
  display: inline-block;
  font-style: normal;
  font-weight: 600;
}
.home-text06 {
  display: inline-block;
  font-style: normal;
  font-weight: 600;
}
.home-text07 {
  display: inline-block;
  font-style: normal;
  font-weight: 600;
}
.home-text08 {
  display: inline-block;
}
.home-text09 {
  display: inline-block;
}
.home-text10 {
  display: inline-block;
}
.home-text11 {
  display: inline-block;
}
.home-text12 {
  display: inline-block;
}
.home-text13 {
  display: inline-block;
}
.home-text14 {
  display: inline-block;
}
.home-text15 {
  display: inline-block;
}
.home-text16 {
  display: inline-block;
}
.home-text17 {
  display: inline-block;
}
.home-text18 {
  display: inline-block;
}
.home-text19 {
  display: inline-block;
}
.home-text20 {
  display: inline-block;
  text-align: center;
}
.home-text21 {
  display: inline-block;
  text-align: center;
}
.home-text22 {
  display: inline-block;
  text-align: center;
}
.home-text23 {
  display: inline-block;
  text-align: center;
}
.home-text24 {
  display: inline-block;
}
.home-text25 {
  display: inline-block;
}
.home-text28 {
  display: inline-block;
}
.home-text29 {
  display: inline-block;
}
.home-text30 {
  display: inline-block;
}
.home-text31 {
  display: inline-block;
}
.home-text32 {
  fill: var(--dl-color-theme-neutral-dark);
  color: var(--dl-color-theme-neutral-dark);
  display: inline-block;
}
@media(max-width: 479px) {
  .home-text25 {
    text-align: center;
  }
}
